<template>
  <div class="bg-white">
    <div :class="`d-flex flex-column ${isMobile ? 'm-5' : 'mx-30 my-10'}`" v-if="eventInfo && ticketList">
      <div class="div-event-image d-flex justify-content-center" v-if="isWide">
        <img class="h-500px rounded-20" :src="hostname === 'localhost' && FOR_MA ? assets.testImage1 : eventInfo.photoUrl" />
      </div>
      <img class="event-image rounded-20" :src="hostname === 'localhost' && FOR_MA ? assets.testImage1 : eventInfo.photoUrl" v-else />
      <div :class="`row ${isMobile ? 'mt-5' : 'mt-10'}`">
        <div class="col-lg-7 mx-auto d-flex flex-column">
          <VueCountdown :class="`${isMobile ? 'mt-5' : 'mt-10'} box-count-down`" :time="eventInfo.countDownTime" v-if="eventInfo.countDownTime && eventInfo.countDownTime > 0 && !isWide">
            <template slot-scope="props">
              <div class="w-100 d-flex justify-content-between vertical-center">
                <div class="w-25 d-flex flex-column text-center">
                  <span :class="`${isMobile ? 'font-15' : 'font-25'} color-white font-event-count-down-time`">{{ zeroPad(props.days, 2) }}</span>
                  <span :class="`${isMobile ? 'font-10' : 'font-12'} color-white font-event-count-down-label`">{{ $t(props.days > 1 ? 'days' : 'day') }}</span>
                </div>
                <span :class="`${isMobile ? 'font-15' : 'font-25'} color-white font-event-count-down-time`" :style="`margin-top: ${isMobile ? '-1px;' : '-2px;'}`">&#58;</span>
                <div class="w-25 d-flex flex-column text-center">
                  <span :class="`${isMobile ? 'font-15' : 'font-25'} color-white font-event-count-down-time`">{{ zeroPad(props.hours, 2) }}</span>
                  <span :class="`${isMobile ? 'font-10' : 'font-12'} color-white font-event-count-down-label`">{{ $t(props.hours > 1 ? 'hours' : 'hour') }}</span>
                </div>
                <span :class="`${isMobile ? 'font-15' : 'font-25'} color-white font-event-count-down-time`" :style="`margin-top: ${isMobile ? '-2px;' : '-2px;'}`">&#58;</span>
                <div class="w-25 d-flex flex-column text-center">
                  <span :class="`${isMobile ? 'font-15' : 'font-25'} color-white font-event-count-down-time`">{{ zeroPad(props.minutes, 2) }}</span>
                  <span :class="`${isMobile ? 'font-10' : 'font-12'} color-white font-event-count-down-label`">{{ $t(props.minutes > 1 ? 'minutes' : 'minute') }}</span>
                </div>
                <span :class="`${isMobile ? 'font-15' : 'font-25'} color-white font-event-count-down-time`" :style="`margin-top: ${isMobile ? '-2px;' : '-2px;'}`">&#58;</span>
                <div class="w-25 d-flex flex-column text-center">
                  <span :class="`${isMobile ? 'font-15' : 'font-25'} color-white font-event-count-down-time`">{{ zeroPad(props.seconds, 2) }}</span>
                  <span :class="`${isMobile ? 'font-10' : 'font-12'} color-white font-event-count-down-label`">{{ $t(props.seconds > 1 ? 'seconds' : 'second') }}</span>
                </div>
              </div>
            </template>
          </VueCountdown>
          <span :class="`font-bold ${isMobile ? 'mt-5 font-20' : 'mt-10 font-25'}`">{{ eventInfo.name }}</span>
          <span :class="`mt-5 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('address') }}</span>
          <div class="mt-2 d-flex">
            <i :class="`flaticon2-pin-1 color-pink ${isMobile ? 'font-15' : 'font-20'}`"></i>
            <div class="ml-2 d-flex flex-column">
              <span :class="`${isMobile ? 'font-12' : 'font-15'}`">{{ eventInfo.hideAddress ? $t('private') : eventInfo.address }}</span>
              <span :class="`mt-2 pointer ${isMobile ? 'font-12' : 'font-15'} color-pink`" @click="openGoogleMap()" v-if="!eventInfo.hideAddress">
                {{ $t('show_in_map') }}
                <i :class="`${isMobile ? 'font-12' : 'font-15'} ml-2 color-pink fa fa-angle-right`"></i>
              </span>
            </div>
          </div>
          <div class="mt-5 vertical-center pointer" @click="isEventInfoExpand = !isEventInfoExpand">
            <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('about_the_event') }}</span>
            <i :class="`ml-2 fa fa-angle-${isEventInfoExpand ? 'up' : 'down'} color-pink ${isMobile ? 'font-12' : 'font-15'}`"></i>
          </div>
          <div class="d-flex flex-column" v-if="isEventInfoExpand">
            <span :class="`mt-2 ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('start_date') }}: {{ getDateStringFromTimestamp(eventInfo.startAt) }}</span>
            <span :class="`mt-2 ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('end_date') }}: {{ getDateStringFromTimestamp(eventInfo.endAt) }}</span>
            <span :class="`mt-5 ${isMobile ? 'font-12' : 'font-15'}`" v-linkified>{{ eventInfo.description }}</span>
          </div>
          <hr class="mt-5" />
          <div class="mt-5 vertical-center pointer" @click="isOrganizerInfoExpand = !isOrganizerInfoExpand">
            <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('information_about_organizer') }}</span>
            <i :class="`ml-2 fa fa-angle-${isOrganizerInfoExpand ? 'up' : 'down'} color-pink ${isMobile ? 'font-12' : 'font-15'}`"></i>
          </div>
          <span :class="`mt-5 ${isMobile ? 'font-12' : 'font-15'}`" v-linkified v-html="$t('alert_information_about_organizer', [organizerInfo.userName, organizerInfo.email || organizerInfo.phone])" v-if="organizerInfo && isOrganizerInfoExpand"></span>
        </div>
        <div :class="`col-lg-5 ${isWide ? 'pl-10' : ''} d-flex flex-column`" v-if="ticketList">
          <hr class="my-5" v-if="!isWide" />
          <VueCountdown :class="`${isMobile ? 'my-5' : 'my-10'} box-count-down`" :time="eventInfo.countDownTime" v-if="eventInfo.countDownTime && eventInfo.countDownTime > 0 && isWide">
            <template slot-scope="props">
              <div class="w-100 d-flex justify-content-between vertical-center">
                <div class="w-25 d-flex flex-column text-center">
                  <span :class="`${isMobile ? 'font-15' : 'font-25'} color-white font-event-count-down-time`">{{ zeroPad(props.days, 2) }}</span>
                  <span :class="`${isMobile ? 'font-10' : 'font-12'} color-white font-event-count-down-label`">{{ $t(props.days > 1 ? 'days' : 'day') }}</span>
                </div>
                <span :class="`${isMobile ? 'font-15' : 'font-25'} color-white font-event-count-down-time`" :style="`margin-top: ${isMobile ? '-1px;' : '-2px;'}`">&#58;</span>
                <div class="w-25 d-flex flex-column text-center">
                  <span :class="`${isMobile ? 'font-15' : 'font-25'} color-white font-event-count-down-time`">{{ zeroPad(props.hours, 2) }}</span>
                  <span :class="`${isMobile ? 'font-10' : 'font-12'} color-white font-event-count-down-label`">{{ $t(props.hours > 1 ? 'hours' : 'hour') }}</span>
                </div>
                <span :class="`${isMobile ? 'font-15' : 'font-25'} color-white font-event-count-down-time`" :style="`margin-top: ${isMobile ? '-2px;' : '-2px;'}`">&#58;</span>
                <div class="w-25 d-flex flex-column text-center">
                  <span :class="`${isMobile ? 'font-15' : 'font-25'} color-white font-event-count-down-time`">{{ zeroPad(props.minutes, 2) }}</span>
                  <span :class="`${isMobile ? 'font-10' : 'font-12'} color-white font-event-count-down-label`">{{ $t(props.minutes > 1 ? 'minutes' : 'minute') }}</span>
                </div>
                <span :class="`${isMobile ? 'font-15' : 'font-25'} color-white font-event-count-down-time`" :style="`margin-top: ${isMobile ? '-2px;' : '-2px;'}`">&#58;</span>
                <div class="w-25 d-flex flex-column text-center">
                  <span :class="`${isMobile ? 'font-15' : 'font-25'} color-white font-event-count-down-time`">{{ zeroPad(props.seconds, 2) }}</span>
                  <span :class="`${isMobile ? 'font-10' : 'font-12'} color-white font-event-count-down-label`">{{ $t(props.seconds > 1 ? 'seconds' : 'second') }}</span>
                </div>
              </div>
            </template>
          </VueCountdown>
          <div class="d-flex flex-column">
            <div class="d-flex flex-column" v-for="(item, index) in ticketList" :key="(item, index)">
              <div class="mt-10 box-extra-description d-flex flex-column" v-if="item.showExtraDescription === true">
                <span :class="`font-bold ${isMobile ? 'font-15' : 'font-18'}`">{{ $t('extra') }}</span>
                <span class="mt-2 font-label">{{ $t('desc_extra') }}</span>
              </div>
              <div :class="`${isMobile ? 'mt-2' : 'mt-3'} d-flex flex-column`" v-if="canBuyTicket()">
                <div class="d-flex p-5 justify-content-between" v-if="item.isExtra">
                  <div class="d-flex flex-column">
                    <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ item.name }}</span>
                    <span class="mt-2 font-label" v-if="item.description">{{ item.description }}</span>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="vertical-center" v-if="checkValidTicketForPromoCode(item)">
                      <span :class="`middle-line font-label ${isMobile ? 'font-12' : 'font-15'}`">{{ getPriceFormat(item.price) }}</span>
                      <span :class="`ml-5 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ getPriceFormat(item.price * (100 - promoInfo.percent) / 100) }}</span>
                    </div>
                    <span :class="`ml-auto font-bold ${isMobile ? 'font-12' : 'font-15'}`" v-else>{{ getPriceFormat(item.price) }}</span>
                    <span :class="`ml-auto mt-2 color-red ${isMobile ? 'font-12' : 'font-15'}`" v-if="item.soldOut === true || item.totalQuantity <= item.soldQuantity">{{ $t('sold_out') }}</span>
                    <div :class="`mt-2 ${isMobile ? 'ml-2' : 'ml-5'} d-flex justify-content-between vertical-center`" v-else>
                      <v-btn icon @click="setQuantity(index, false)" :disabled="item.quantity < 1">
                        <img class="ml-1" :height="isMobile ? 27 : 30" :src="assets.minusEnabled" v-if="item.quantity > 0" />
                        <img class="ml-1" :height="isMobile ? 27 : 30" :src="assets.minusDisabled" v-else />
                      </v-btn>
                      <span :class="`w-20px center font-bold mx-3 ${isMobile ? 'font-15' : 'font-18'}`">{{ getDecimalFormat(item.quantity) }}</span>
                      <v-btn icon @click="setQuantity(index, true)">
                        <img class="ml-1" :height="isMobile ? 27 : 30" :src="assets.plusEnabled" />
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column" v-else>
                  <div class="box-ticket-content d-flex flex-column">
                    <span :class="`mb-2 font-bold color-red ${isMobile ? 'font-10' : 'font-12'}`" v-if="item.fewTicketsAvailable === true">•&nbsp;{{ $t('few_tickets_available') }}</span>
                    <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ item.name }}</span>
                    <span class="mt-2 font-label" v-if="item.description">{{ item.description }}</span>
                    <span class="mt-3 font-12" v-if="item.minQuantity > 0">{{ $t('minimum') }} {{ item.minQuantity }} - {{ $t('maximum') }} {{ item.maxQuantity }}</span>
                  </div>
                  <div :class="`${isMobile ? 'mx-5 mt-2' : 'mx-5 mt-5'} d-flex justify-content-between vertical-center`">
                    <span :class="`font-bold font-label ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('cost') }}</span>
                    <div class="vertical-center" v-if="checkValidTicketForPromoCode(item)">
                      <span :class="`middle-line font-label ${isMobile ? 'font-12' : 'font-15'}`">{{ getPriceFormat(item.price) }}</span>
                      <span :class="`ml-5 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ getPriceFormat(item.price * (100 - promoInfo.percent) / 100) }}</span>
                    </div>
                    <span :class="`ml-5 font-bold ${isMobile ? 'font-12' : 'font-15'}`" v-else>{{ getPriceFormat(item.price) }}</span>
                  </div>
                  <hr class="my-2" />
                  <div class="mx-5 d-flex justify-content-between vertical-center">
                    <span :class="`font-bold font-label ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('number') }}</span>
                    <span :class="`ml-auto color-red ${isMobile ? 'font-12' : 'font-15'}`" v-if="item.soldOut === true || item.totalQuantity <= item.soldQuantity">{{ $t('sold_out') }}</span>
                    <div :class="`${isMobile ? 'ml-2' : 'ml-5'} mr-2 center`" v-else-if="!item.vipPassed">
                      <v-btn icon @click="enterVipPassword(index)">
                        <i :class="`fa fa-crown color-pink ${isMobile ? 'font-15' : 'font-20'}`"></i>
                      </v-btn>
                    </div>
                    <div :class="`${isMobile ? 'ml-2' : 'ml-5'} d-flex justify-content-between vertical-center`" v-else>
                      <v-btn icon @click="setQuantity(index, false)" :disabled="item.quantity < 1">
                        <img class="ml-1" :height="isMobile ? 27 : 30" :src="assets.minusEnabled" v-if="item.quantity > 0" />
                        <img class="ml-1" :height="isMobile ? 27 : 30" :src="assets.minusDisabled" v-else />
                      </v-btn>
                      <span :class="`w-20px center font-bold mx-3 ${isMobile ? 'font-15' : 'font-18'}`">{{ getDecimalFormat(item.quantity) }}</span>
                      <v-btn icon @click="setQuantity(index, true)">
                        <img class="ml-1" :height="isMobile ? 27 : 30" :src="assets.plusEnabled" />
                      </v-btn>
                    </div>
                  </div>
                  <hr class="my-2" v-if="item.quantity > 0" />
                  <div class="mx-5 d-flex justify-content-between vertical-center" v-if="item.quantity > 0">
                    <span :class="`font-bold font-label ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('subtotal') }}</span>
                    <div class="vertical-center" v-if="checkValidTicketForPromoCode(item)">
                      <span :class="`middle-line font-label ${isMobile ? 'font-12' : 'font-15'}`">{{ getPriceFormat(item.price * item.quantity) }}</span>
                      <span :class="`${isMobile ? 'ml-2' : 'ml-5'} font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ getPriceFormat((item.price * (100 - promoInfo.percent) / 100) * item.quantity) }}</span>
                    </div>
                    <span :class="`font-bold ${isMobile ? 'ml-2 font-12' : 'ml-5 font-15'}`" v-else>{{ getPriceFormat(item.price * item.quantity) }}</span>
                  </div>
                </div>
              </div>
              <div :class="`mt-3 ${!item.isExtra ? 'box-ticket-content' : 'p-5'} d-flex justify-content-between vertical-center`" v-else>
                <div class="d-flex flex-column">
                  <span :class="`mb-2 font-bold color-red ${isMobile ? 'font-10' : 'font-12'}`" v-if="item.fewTicketsAvailable === true">•&nbsp;{{ $t('few_tickets_available') }}</span>
                  <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ item.name }}</span>
                  <span class="mt-2 font-label" v-if="item.description">{{ item.description }}</span>
                  <span class="mt-3 font-12" v-if="item.minQuantity > 0">{{ $t('minimum') }} {{ item.minQuantity }} - {{ $t('maximum') }} {{ item.maxQuantity }}</span>
                </div>
                <div class="ml-5 d-flex flex-column">
                  <span :class="`ml-auto font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ getPriceFormat(item.price) }}</span>
                  <span :class="`ml-auto mt-2 font-bold ${isMobile ? 'font-12' : 'font-15'}`" v-if="isMyEvent">{{ getDecimalFormat(item.soldQuantity) }} / {{ getDecimalFormat(item.totalQuantity) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div :class="`${isMobile ? 'mt-5' : 'mt-10'} box-ticket`" v-if="canBuyTicket() && getSummary()">
            <div class="d-flex flex-column" v-for="(item, index) in ticketList.filter(element => element.quantity > 0)" :key="(item, index)">
              <div :class="`${isMobile ? 'mt-1' : 'mt-2'} d-flex justify-content-between vertical-center`">
                <span class="font-label">{{ item.quantity }} x {{ item.name }}</span>
                <span class="font-12">{{ getPriceFormat(item.price * item.quantity) }}</span>
              </div>
            </div>
            <div :class="`${isMobile ? 'mt-2' : 'mt-3'} d-flex justify-content-between vertical-center`" v-if="getSummary().promoDiscount">
              <span class="font-label">{{ $t('discount') }}</span>
              <span class="font-12 color-red">{{ getPriceFormat(getSummary().promoDiscount * -1) }}</span>
            </div>
            <div :class="`${isMobile ? 'mt-2' : 'mt-3'} d-flex justify-content-between vertical-center`">
              <span class="font-label">{{ $t('service_charge') }}</span>
              <span class="font-12">{{ getPriceFormat(getSummary().serviceCharge) }}</span>
            </div>
            <div :class="`${isMobile ? 'mt-1' : 'mt-2'} d-flex justify-content-between vertical-center`">
              <span class="font-label font-bold">{{ $t('total') }}</span>
              <span class="font-bold font-15">{{ getPriceFormat(getSummary().totalPrice) }}</span>
            </div>
            <div :class="`${isMobile ? 'mt-1' : 'mt-2'} d-flex justify-content-end`" v-if="getSummary().totalPrice > 0">
              <span class="font-label">{{ $t('price_includes_tax') }}</span>
            </div>
          </div>
          <span class="mt-5">&nbsp;</span>
          <div :class="`${isMobile ? 'mt-2' : 'mt-5'} box-ticket d-flex flex-column`" v-if="canBuyTicket() && promoList.length">
            <span class="font-label">{{ $t('promo_code') }}</span>
            <div class="w-100 position-relative">
              <input class="mt-2 pr-25 form-control" v-model="promoCode" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g,'');" :disabled="promoInfo" />
              <div class="position-absolute right-0 top-0 mt-4 mr-2">
                <div class="d-flex" v-if="promoInfo">
                  <i class="mt-1 fa fa-check-circle font-20 color-blue"></i>
                  <v-btn text small @click="checkEnteredPromoCode()">
                    <span class="font-12">{{ $t('remove') }}</span>
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn text small @click="checkEnteredPromoCode()" v-if="promoValid === null && promoCode">
                    <span class="font-12">{{ $t('apply') }}</span>
                  </v-btn>
                  <i class="mt-1 mr-2 fa fa-exclamation-circle font-20 color-red" v-if="promoValid === false"></i>
                </div>
              </div>
            </div>
            <span class="mt-2 ml-2 font-label color-blue" v-if="promoInfo">{{ $t('alert_correct_promo_code', [promoInfo.code, promoInfo.percent]) }}</span>
            <span class="mt-2 ml-2 font-label color-red" v-else-if="promoValid === false">{{ $t('alert_incorrect_promo_code') }}</span>
            <span class="mt-2 font-label" v-else>&nbsp;</span>
          </div>
          <div :class="`${isMobile ? 'mt-2' : 'mt-5'} box-ticket`" v-if="!myInfo && canBuyTicket()">
            <span class="ml-2 font-label">{{ $t('email') }}</span>
            <input id="inputEmail" class="mt-1 form-control" v-model="email" onpaste="return false;" oninput="this.value = this.value.replace(/[ ]/g,'');" />
            <br/>
            <span class="ml-2 font-label">{{ $t('repeat_email') }}</span>
            <input class="mt-1 form-control" v-model="repeat_email" onpaste="return false;" oninput="this.value = this.value.replace(/[ ]/g,'');" />
            <br/>
            <span class="my-2 font-label color-blue">{{ $t('desc_enter_email_exactly_required_for_next_login') }}</span>
          </div>
          <div :class="`${isMobile ? 'mt-2' : 'mt-5'} d-flex justify-content-center`" v-if="canBuyTicket() && channelId">
            <div class="box-promoter center d-flex flex-column" @click="$router.push(`/c/${channelId}`)">
              <span class="font-15 font-bold color-white">{{ $t('tickets_for_free') }}</span>
              <span class="mt-2 font-12 color-white">{{ $t('become_a_promoter') }}</span>
            </div>
          </div>
          <span class="m-10">&nbsp;</span>
          <div :class="`div-bottom-buy ${isMobile ? 'w-100' : 'row px-25'}`" v-if="canBuyTicket()">
            <span class="col-lg-7" v-if="!isMobile">&nbsp;</span>
            <div :class="`${isMobile ? 'w-100 p-5' : 'col-lg-5 py-5 pl-15'} d-flex justify-content-center`">
              <v-btn class="w-100 button-normal" @click="buyTicket()" :disabled="!getSummary()">
                <span class="px-10">{{ getSummary() ? `${$t('buy')} (${getPriceFormat(getSummary().totalPrice)})` : $t('buy') }}</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-30 py-40 center" v-else>
      <b-spinner type="grow"></b-spinner>
      <p class="mt-5">{{ $t('alert_please_wait') }}</p>
    </div>
  </div>
</template>

<style scoped>
  .div-event-image {
    width: 100% !important;
    height: 500px !important;
    background-image: url('~@/assets/images/event_holder.png') !important;
    background-size: 100% 100% !important;
  }
  .box-count-down {
    padding: 15px !important;
    background-color: #4F4F5B !important;
    border-radius: 10px !important;
  }
  .box-ticket {
    padding: 15px !important;
    border-radius: 10px !important;
    border: 1px solid #E0E0E0 !important;
  }
  .box-ticket-content {
    padding: 15px !important;
    background-color: #F9EDF8 !important;
    border-radius: 10px !important;
  }
  .box-extra-description {
    padding: 15px !important;
    background-color: rgba(255, 131, 0, 0.6) !important;
    border-radius: 10px !important;
  }
  .box-promoter {
    cursor: pointer !important;
    padding: 10px 50px !important;
    background: linear-gradient(45deg, #7C33E8, #F9854A);
    border-radius: 20px !important;
    box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
    -moz-box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
    -webkit-box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
  }
  .box-promoter:hover {
    transform: scale(1.1)
  }
  .div-bottom-buy {
    width: 100% !important;
    background-color: white !important;
    position: fixed !important;
    bottom: 0px !important;
    left: 0px !important;
    z-index: 99;
  }
</style>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';

import { httpsCallable } from 'firebase/functions';
import { firebaseAuth, functions, firestore } from '../../../main';
import { onSnapshot, getDocs, doc, query, collection, where, limit } from 'firebase/firestore';
import { isFutureTimestamp, getDateStringFromTimestamp, getDecimalFormat, getPriceFormat, zeroPad, getEventParam, isValidEmail, showLoading, showFunctionError } from '../../../functions';

import testImage1 from '@/assets/images/test_image1.jpg';
import minusDisabled from '@/assets/images/minus-disabled.png';
import minusEnabled from '@/assets/images/minus-enabled.png';
import plusEnabled from '@/assets/images/plus-enabled.png';

export default {
  name: 'EventDetail',
  components: {
    VueCountdown
  },
  metaInfo() {
    const title = this.eventInfo ? 'eventbox | ' + this.eventInfo.name : 'eventbox';
    const description = this.eventInfo ? this.eventInfo.description : '';
    const imageUrl = this.eventInfo ? this.eventInfo.photoUrl : '';
    const siteUrl = `https://eventboxde.com/e/${this.$route.params.eventParam}`;
    return {
      title: title,
      meta: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: siteUrl },
      { property: 'og:site_name', content: 'eventbox' },
      { property: 'og:image', content: imageUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:domain', content: 'eventboxde.com' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:image', content: imageUrl },
      ]
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    },
    myInfo() {
      return this.$store.state.myInfo;
    },
    serviceChargeInfo() {
      return this.$store.state.serviceChargeInfo;
    }
  },
  watch: {
    serviceChargeInfo() {
      this.enterPromoCode();
    },
    promoCode() {
      this.promoValid = null;
    }
  },
  data() {
    return {
      MAX_QUANTITY_LIMIT: 15,
      perPageCustom: [
        [400, 2],
        [800, 3],
        [1200, 4],
        [1600, 5]
      ],
      email: '',
      repeat_email: '',
      isEventInfoExpand: false,
      isOrganizerInfoExpand: false,
      fbqInited: false,
      ttqInited: false,
      eventInfo: null,
      organizerInfo: null,
      ticketList: null,
      promoList: [],
      promoInfo: null,
      promoCode: '',
      promoValid: null,
      channelId: '',
      snapEventInfo: null,
      snapUserInfo: null,
      snapTicketList: null,
      snapPromoList: null,
      isMyEvent: false,
      hostname: window.location.hostname,
      assets: {
        testImage1,
        minusDisabled,
        minusEnabled,
        plusEnabled
      }
    };
  },
  mounted() {
    if (firebaseAuth.currentUser) {
      this.loadData();
    } else {
      firebaseAuth.onAuthStateChanged(user => {
        if (user) {
          this.loadData();
        }
      });
    }
  },
  destroyed() {
    if (this.snapEventInfo) {
      this.snapEventInfo();
    }
    if (this.snapUserInfo) {
      this.snapUserInfo();
    }
    if (this.snapTicketList) {
      this.snapTicketList();
    }
    if (this.snapPromoList) {
      this.snapPromoList();
    }
  },
  methods: {
    getDecimalFormat,
    getPriceFormat,
    zeroPad,
    getDateStringFromTimestamp,
    loadData() {
      if (this.$route.params.eventParam.includes('-')) {
        this.searchEventInfoTask();
      } else {
        this.getEventInfoTask();
      }
    },
    openGoogleMap() {
      if (this.eventInfo && this.eventInfo.geoPoint && !this.eventInfo.hideAddress) {
        const url = `https://maps.google.com/?q=${this.eventInfo.geoPoint.latitude},${this.eventInfo.geoPoint.longitude}`;
        window.open(url, '_blank');
      }
    },
    canBuyTicket() {
      if (this.myInfo && this.myInfo.userType === this.USER_ORGANIZER) {
        return false;
      } else {
        if (this.eventInfo && this.serviceChargeInfo && this.ticketList && this.ticketList.length > 0) {
          return isFutureTimestamp(this.eventInfo.endAt);
        } else {
          return false;
        }
      }
    },
    enterVipPassword(index) {
      if (index >= 0 && index < this.ticketList.length && this.ticketList[index].vipPassword) {
        const entered = window.prompt(this.$t('vip_password'));
        if (this.ticketList[index].vipPassword === entered) {
          this.ticketList[index].vipPassed = true;
        } else {
          this.$toast.error(this.$t('alert_incorrect_password'));
        }
      }
    },
    enterPromoCode() {
      if (this.canBuyTicket() && this.promoList.length > 0 && this.$route.params.promoCode) {
        this.promoCode = this.$route.params.promoCode;
        this.checkEnteredPromoCode();
      }
    },
    checkEnteredPromoCode() {
      if (this.promoInfo) {
        this.promoCode = '';
        this.promoInfo = null;
        this.promoValid = null;
      } else {
        const promoInfo = this.promoList.find(element => element.code === this.promoCode);
        if (promoInfo && this.checkValidPromoCode(promoInfo)) {
          this.promoInfo = promoInfo;
          this.promoValid = true;
          if (promoInfo.totalQuantity > 0) {
            const availableQuantity = promoInfo.totalQuantity - promoInfo.usedQuantity;
            const totalQuantity = this.getTotalValidTicketForPromoCodeQuantity();
            if (totalQuantity > availableQuantity) {
              var totalOffset = totalQuantity - availableQuantity;
              this.ticketList.forEach(ticketInfo => {
                const offset = Math.min(ticketInfo.quantity, totalOffset);
                if (offset > 0 && ticketInfo.price > 0) {
                  ticketInfo.quantity -= offset;
                  totalOffset -= offset;
                  if (ticketInfo.quantity < ticketInfo.minQuantity) {
                    ticketInfo.quantity = 0;
                  }
                }
              });
              this.$toast.error(this.$t('alert_you_can_only_buy_max__tickets_with_promo_code', [availableQuantity]));
            }
          }
        } else {
          this.promoInfo = null;
          this.promoValid = false;
        }
      }
    },
    checkValidPromoCode(promoInfo) {
      if (promoInfo.totalQuantity > 0 && promoInfo.usedQuantity >= promoInfo.totalQuantity) {
        return false;
      }
      if (promoInfo.startAt && isFutureTimestamp(promoInfo.startAt)) {
        return false;
      }
      if (promoInfo.endAt && !isFutureTimestamp(promoInfo.endAt)) {
        return false;
      }
      return true;
    },
    checkValidTicketForPromoCode(ticketInfo) {
      return ticketInfo.price > 0 && this.promoInfo && (this.promoInfo.ticketIDs.length === 0 || this.promoInfo.ticketIDs.includes(ticketInfo.ticketId));
    },
    getTotalValidTicketForPromoCodeQuantity() {
      var totalQuantity = 0;
      this.ticketList.forEach(ticketInfo => {
        if (this.checkValidTicketForPromoCode(ticketInfo)) {
          totalQuantity += ticketInfo.quantity;
        }
      });
      return totalQuantity;
    },
    setQuantity(index, isPlus) {
      if (this.ticketList && index >= 0 && index < this.ticketList.length) {
        const ticketInfo = this.ticketList[index];
        const remainingQuantity = ticketInfo.totalQuantity - ticketInfo.soldQuantity;
        if (isPlus) {
          const offset = ticketInfo.minQuantity > 0 && ticketInfo.quantity == 0 ? ticketInfo.minQuantity : 1;
          if (this.checkValidTicketForPromoCode(ticketInfo) && this.promoInfo.totalQuantity > 0) {
            const totalQuantity = this.getTotalValidTicketForPromoCodeQuantity();
            const availableQuantity = this.promoInfo.totalQuantity - this.promoInfo.usedQuantity;
            if (totalQuantity + offset > availableQuantity) {
              this.$toast.error(this.$t('alert_you_can_only_buy_max__tickets_with_promo_code', [availableQuantity]));
              return;
            }
          }
          if (ticketInfo.quantity + offset > remainingQuantity) {
            this.$toast.error(this.$t('alert_no_ticket', [remainingQuantity]));
            return;
          }
          /* if (ticketInfo.vipPassword && ticketInfo.quantity > 0) {
            this.$toast.error(this.$t('alert_you_can_only_buy_1_vip_ticket'));
            return;
          } */
          if (ticketInfo.quantity >= this.MAX_QUANTITY_LIMIT) {
            this.$toast.error(this.$t('alert_you_can_only_buy_max__tickets', [this.MAX_QUANTITY_LIMIT]));
            return;
          }
          if (ticketInfo.maxQuantity > 0 && ticketInfo.quantity >= ticketInfo.maxQuantity) {
            this.$toast.error(this.$t('alert_you_can_only_buy_max__tickets', [ticketInfo.maxQuantity]));
            return;
          }
          this.ticketList[index].quantity += offset;
        } else {
          const minQuantity = ticketInfo.minQuantity > 0 ? ticketInfo.minQuantity : 1;
          if (ticketInfo.quantity >= minQuantity) {
            this.ticketList[index].quantity -= ticketInfo.quantity == minQuantity ? minQuantity : 1;
          }
        }
      }
    },
    getSummary() {
      if (this.canBuyTicket() && this.ticketList && this.ticketList.find(element => element.quantity > 0)) {
        var ticketPrice = 0;
        var promoDiscount = 0;
        this.ticketList.filter(element => element.quantity > 0).forEach(ticketInfo => {
          const priceAmount = ticketInfo.price * ticketInfo.quantity;
          ticketPrice += priceAmount;
          if (this.checkValidTicketForPromoCode(ticketInfo)) {
            promoDiscount += Math.round(priceAmount * this.promoInfo.percent / 100);
          }
        });
        const summaryInfo = {
          ticketPrice: ticketPrice,
          promoDiscount: promoDiscount
        }
        summaryInfo['serviceCharge'] = ticketPrice > 0 ? Math.round((ticketPrice - promoDiscount) * this.serviceChargeInfo.serviceTax) + this.serviceChargeInfo.serviceFee : 0;
        summaryInfo['totalPrice'] = ticketPrice - promoDiscount + summaryInfo['serviceCharge'];
        return summaryInfo;
      } else {
        return null;
      }
    },
    buyTicket() {
      const details = [];
      this.ticketList.filter(element => element.quantity > 0).forEach(ticketInfo => {
        details.push({
          ticketId: ticketInfo.ticketId,
          name: ticketInfo.name,
          price: ticketInfo.price,
          quantity: ticketInfo.quantity,
          eventDays: ticketInfo.eventDays || 0,
          isExtra: false
        })
      });
      if (details.length > 0) {
        const summaryInfo = this.getSummary();
        const transactionInfo = {
          organizerId: this.eventInfo.userId,
          buyerId: this.$store.state.myId,
          eventId: this.eventInfo.eventId,
          ticketPrice: summaryInfo.ticketPrice,
          promoDiscount: summaryInfo.promoDiscount,
          serviceCharge: summaryInfo.serviceCharge,
          totalPrice: summaryInfo.totalPrice,
          details: details
        };
        if (this.myInfo) {
          this.doBuyTicket(transactionInfo);
        } else {
          this.getUserOrRegisterGuestTask(transactionInfo);
        }
      } else {
        this.$toast.error(this.$t('alert_unexpected_error'));
      }
    },
    doBuyTicket(transactionInfo) {
      this.$gtag.event('add_to_cart', {
        'event_name': this.eventInfo.name,
        'total_price': transactionInfo.totalPrice
      });
      if (this.fbqInited) {
        window.fbq('track', `AddToCart: [${this.eventInfo.name}]`);
      }
      if (this.ttqInited) {
        window.ttq.track('AddToCart', {
          description: `Event: ${this.eventInfo.name}`
        });
      }
      if (transactionInfo.totalPrice > 0) {
        if (this.promoInfo) {
          transactionInfo['promoId'] = this.promoInfo.promoId;
          transactionInfo['promoCode'] = this.promoInfo.code;
          transactionInfo['promoPercent'] = this.promoInfo.percent;
        }
        localStorage.setItem('eventboxTransactionInfo', JSON.stringify(transactionInfo));
        localStorage.setItem('eventboxPaymentMethod', '');
        this.$router.push(`/payment/checkout`);
      } else {
        this.buyTicketTask(transactionInfo);
      }
    },
    handleEventInfo(eventInfo) {
      if (eventInfo) {
        if (eventInfo.deletedAt) {
          this.$toast.error(this.$t('alert_this_event_has_been_deleted'));
          this.$router.push(`/live_events`);
        } else {
          if (eventInfo.facebookPixelId && !this.fbqInited) {
            window.fbq('init', eventInfo.facebookPixelId);
            window.fbq('track', `PageView: [${eventInfo.name}]`);
            this.fbqInited = true;
          }
          if (eventInfo.tikTokPixelId && !this.ttqInited) {
            window.ttq.load(eventInfo.tikTokPixelId);
            window.ttq.page();
            window.ttq.track('ViewContent', {
              description: `Event: ${eventInfo.name}`
            });
            this.ttqInited = true;
          }
          if (eventInfo.startAt && isFutureTimestamp(eventInfo.startAt) && eventInfo.displayCountDown === true) {
            eventInfo['countDownTime'] = eventInfo.startAt.seconds * 1000 - new Date().getTime();
          }
          this.isMyEvent = eventInfo.userId === this.$store.state.myId;
          this.eventInfo = eventInfo;
          this.getUserInfoTask(eventInfo.userId);
          this.getTicketListTask(eventInfo.eventId);
          this.getPromoListTask(eventInfo.eventId);
          this.searchChannelInfoTask(eventInfo.eventId);
        }
      } else {
        this.$toast.error(this.$t('no_data_found'));
        this.$router.push(`/live_events`);
      }
    },
    searchEventInfoTask() {
      if (this.snapEventInfo) {
        return;
      }
      var invoiceNumber = '';
      const splits = this.$route.params.eventParam.split('-');
      if (splits && splits.length) {
        invoiceNumber = splits[splits.length - 1];
      }
      if (!invoiceNumber && isNaN(invoiceNumber)) {
        this.$toast.error(this.$t('no_data_found'));
        this.$router.push(`/live_events`);
        return;
      }
      const q = query(collection(firestore, 'event'), where('invoiceNumber', '==', parseInt(invoiceNumber)), limit(1));
      this.snapEventInfo = onSnapshot(q, querySnapshot => {
        var eventInfo = null;
        querySnapshot.forEach(doc => {
          const data = doc.data();
          const eventParam = getEventParam(data);
          if (this.$route.params.eventParam === eventParam) {
            eventInfo = data;
          }
        });
        this.handleEventInfo(eventInfo);
      });
    },
    getEventInfoTask() {
      if (this.snapEventInfo) {
        return;
      }
      this.snapEventInfo = onSnapshot(doc(firestore, 'event', this.$route.params.eventParam), doc => {
        this.handleEventInfo(doc.data());
      });
    },
    getUserInfoTask(userId) {
      if (this.snapUserInfo) {
        return;
      }
      this.snapUserInfo = onSnapshot(doc(firestore, 'user', userId), doc => {
        this.organizerInfo = doc.data();
      });
    },
    getTicketListTask(eventId) {
      if (this.snapTicketList) {
        return;
      }
      const q = query(collection(firestore, 'ticket'), where('eventId', '==', eventId));
      this.snapTicketList = onSnapshot(q, querySnapshot => {
        const ticketList1 = [];
        const ticketList2 = [];
        const extraList1 = [];
        const extraList2 = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data.hideTicket !== true) {
            data['quantity'] = 0;
            data['vipPassed'] = !data.vipPassword;
            if (!('totalQuantity' in data)) {
              data['totalQuantity'] = 0;
            }
            if (!('minQuantity' in data)) {
              data['minQuantity'] = 0;
            }
            if (!('maxQuantity' in data)) {
              data['maxQuantity'] = 0;
            }
            if (!('soldQuantity' in data)) {
              data['soldQuantity'] = 0;
            }
            if (data.soldOut !== true && data.totalQuantity > data.soldQuantity) {
              if (data.isExtra === true) {
                extraList1.push(data);                
              } else {
                ticketList1.push(data);
              }
            } else {
              if (data.isExtra === true) {
                extraList2.push(data);
              } else {
                ticketList2.push(data);
              }
            }
          }
        });
        ticketList1.sort((a, b) => (a.price > b.price) ? 1 : -1);
        ticketList2.sort((a, b) => (a.price > b.price) ? 1 : -1);
        extraList1.sort((a, b) => (a.price > b.price) ? 1 : -1);
        extraList2.sort((a, b) => (a.price > b.price) ? 1 : -1);
        const ticketList = ticketList1;
        ticketList.push(...ticketList2);
        if (extraList1.length > 0) {
          extraList1[0]['showExtraDescription'] = true;
        } else if (extraList2.length > 0) {
          extraList2[0]['showExtraDescription'] = true;
        }
        ticketList.push(...extraList1);
        ticketList.push(...extraList2);
        this.ticketList = ticketList;
      });
    },
    getPromoListTask(eventId) {
      if (this.snapPromoList) {
        return;
      }
      const q = query(collection(firestore, 'promoCode'), where('eventId', '==', eventId));
      this.snapPromoList = onSnapshot(q, querySnapshot => {
        const promoList = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (this.checkValidPromoCode(data)) {
            if (!('ticketIDs' in data)) {
              data['ticketIDs'] = [];
            }
            promoList.push(data);
          }
        });
        this.promoList = promoList;
        this.enterPromoCode();
      });
    },
    searchChannelInfoTask(eventId) {
      const q = query(collection(firestore, 'channel'), where('eventId', '==', eventId), limit(1));
      getDocs(q).then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.channelId = doc.id;
        });
      });
    },
    getUserOrRegisterGuestTask(transactionInfo) {
      if (!this.email) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('email')]));
        const inputEmail = document.getElementById('inputEmail');
        if (inputEmail) {
          inputEmail.scrollIntoView();
          inputEmail.focus();
        }
        return;
      }
      if (!isValidEmail(this.email)) {
        this.$toast.error(this.$t('alert_invalid_email'));
        return;
      }
      if (!this.repeat_email) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('repeat_email')]));
        return;
      }
      if (this.email !== this.repeat_email) {
        this.$toast.error(this.$t('alert_emails_not_match'));
        return;
      }
      const params = {
        forGuest: true,
        email: this.email
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'registerUser');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        let userInfo;
        try {
          userInfo = JSON.parse(response.data);
        } catch(exception) {
          userInfo = null;
        }
        if (userInfo && userInfo.userId) {
          transactionInfo.buyerId = userInfo.userId;
          localStorage.setItem('eventboxGuestInfo', JSON.stringify(userInfo));
          this.doBuyTicket(transactionInfo);
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    buyTicketTask(params) {
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'buyTicket');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('alert_success'));
          this.$router.push(`/my_tickets`);
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>